// Remove `no-js` class
document.documentElement.classList.remove('no-js')

// Add your codes here
$(function () {
  $(document).scroll(function () {
    var $nav = $('#navbar')
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height())
  })
})
$(document).ready(function () {
  $('.navbar-nav li').on('click', function () {
    $(this).siblings().removeClass('active')
    $(this).addClass('active')
  })
})
// $(function () {
//   $('.box-item').slice(0, 15).show()
//   $('body').on('click touchstart', '.load-more', function (e) {
//     e.preventDefault()
//     $('.box-item:hidden').slice(0, 15).slideDown()
//     if ($('.box-item:hidden').length === 0) {
//       $('.load-more').css('visibility', 'hidden')
//     }
//     // $('html,body').animate(
//     //   {
//     //     scrollTop: $(this).offset().top
//     //   },
//     //   1000
//     // )
//   })
// })
$(window)
  .scroll(function () {
    const windscroll = $(window).scrollTop()
    if (windscroll >= 100) {
      $('.anchor').each(function (i) {
        // The number at the end of the next line is how pany pixels you from the top you want it to activate.
        if ($(this).position().top <= windscroll - 0) {
          $('nav ul li.active').removeClass('active')
          $('nav ul li').eq(i).addClass('active')
        }
      })
    } else {
      $('nav ul li.active').removeClass('active')
      // $('nav ul li:first').addClass('active')
    }
  })
  .scroll()

$('.navbar-collapse ul li a').click(function (e) {
  $('#navbarCollapse').removeClass('show')
  $('.fixed-top').removeClass('full-height')
  $('body').removeClass('menu-opened')
})

$('.navbar .navbar-toggler').click(function (e) {
  $('.fixed-top').toggleClass('full-height')
  $('body').toggleClass('menu-opened')
})

$('#volume-desk').click(function () {
  if ($('video.desktop-on').prop('muted')) {
    $('video.desktop-on').prop('muted', false)
    $(this).toggleClass('unmute')
  } else {
    $('video.desktop-on').prop('muted', true)
    $(this).toggleClass('unmute')
  }
})
$('#volume-mob').click(function () {
  if ($('video.mobile-on').prop('muted')) {
    $('video.mobile-on').prop('muted', false)
    $(this).toggleClass('unmute')
  } else {
    $('video.mobile-on').prop('muted', true)
    $(this).toggleClass('unmute')
  }
})

jQuery(document).ready(
  (function ($) {
    // Scroll to the desired section on click
    // Make sure to add the `data-scroll` attribute to your `<a>` tag.
    // Example:
    // `<a data-scroll href="#my-section">My Section</a>` will scroll to an element with the id of 'my-section'.
    function scrollToSection(event) {
      event.preventDefault()
      var $section = $($(this).attr('href'))
      $('html, body').animate(
        {
          scrollTop: $section.offset().top
        },
        500
      )
    }
    $('[data-scroll]').on('click', scrollToSection)
  })(jQuery)
)
